<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="posturl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      title:'Checklist',
      posturl:'/checklist',
      fields :[
        { key:'spb', label:'SPB', placeholder:'Pilih No SPB', type: 'select2',rules:'required', dataurl:'spb?length=1000', key_value:'number' },
        { key:'staff', label:'Pemeriksa', placeholder:'Pilih Pemeriksa', type: 'select2',rules:'required', dataurl:'staff' },
      ],
    }
  },
}
</script>